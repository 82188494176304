// import React from 'react';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import LandingPage from './pages/LandingPage';
// import GiftQuestionnaire from './pages/GiftQuestionnaire';

// function App() {
//   return (
//     <BrowserRouter>
//       <Routes>
//         <Route path="/" element={<LandingPage />} />
//         <Route path="/questionnaire" element={<GiftQuestionnaire />} />
//       </Routes>
//     </BrowserRouter>
//   );
// }

// export default App;

// frontend/src/App.js

// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import GiftQuestionnaire from './pages/GiftQuestionnaire';
import ResultsPage from './pages/ResultsPage';
import BlogPage from './components/BlogPage';
import FAQPage from './components/FAQ';
// Import other necessary components

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/questionnaire" element={<GiftQuestionnaire />} />
        <Route path="/results" element={<ResultsPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/faq" element={<FAQPage />} />
        {/* Add other routes as needed */}
      </Routes>
    </Router>
  );
};

export default App;

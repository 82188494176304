import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Calendar, Clock, Search, ChevronRight } from 'lucide-react';
import illustration from '../assets/images/illustration.png';
import logo from '../assets/images/log.png';

const categories = ["All", "Tech", "Fashion", "Home", "Kids", "Special Occasions"];

// You would typically fetch this from your backend
const blogPosts = [
  {
    id: 1,
    title: "Top 10 Tech Gifts for 2024",
    excerpt: "Discover the latest and most exciting tech gifts that will impress your loved ones...",
    content: "Full blog post content here...",
    imageUrl: "/api/placeholder/800/400",
    author: "Jane Smith",
    date: "2024-03-15",
    readTime: "5 min read",
    category: "Tech"
  },
  // Add more blog posts...
];

const BlogPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  const filteredPosts = blogPosts.filter(post => {
    const matchesCategory = selectedCategory === "All" || post.category === selectedCategory;
    const matchesSearch = post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         post.excerpt.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  return (
    <div className="relative min-h-screen bg-gradient-to-r from-yellow-100 via-white to-yellow-100">
      {/* Background Image */}
      <div className="absolute inset-0">
        <img
          src={illustration}
          alt="Background Illustration"
          className="w-full h-full object-cover opacity-20"
        />
      </div>

      {/* Navbar */}
      <nav className="p-4 relative z-20">
        <div className="max-w-7xl mx-auto flex items-center">
          <Link to="/" className="flex items-center">
            <img
              src={logo}
              alt="SuggestMeGifts Logo"
              className="h-20 w-20 mr-4 transform scale-110"
            />
            <span className="text-black text-2xl md:text-3xl lg:text-4xl font-signature">
              Suggest Me Gifts
            </span>
          </Link>
        </div>
      </nav>

      <main className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="max-w-4xl mx-auto mb-12">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl font-bold text-gray-900 mb-6 text-center"
          >
            Gift Guides & Ideas
          </motion.h1>
        </div>

        {/* Search and Filter Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="max-w-4xl mx-auto bg-white/40 backdrop-blur-md rounded-xl shadow-xl p-8 border border-white/50 mb-12"
        >
          {/* Search Bar */}
          <div className="relative mb-8">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              placeholder="Search blog posts..."
              className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500 bg-white/80"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          {/* Categories */}
          <div className="flex flex-wrap gap-4">
            {categories.map((category) => (
              <motion.button
                key={category}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`px-4 py-2 rounded-full transition-colors ${
                  selectedCategory === category
                    ? 'bg-red-500 text-white'
                    : 'bg-white text-gray-700 hover:bg-red-50'
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </motion.button>
            ))}
          </div>
        </motion.div>

        {/* Blog Posts Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          <AnimatePresence>
            {filteredPosts.map((post, index) => (
              <motion.article
                key={post.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white/40 backdrop-blur-md rounded-xl shadow-xl overflow-hidden hover:shadow-2xl transition-shadow border border-white/50"
              >
                {/* <img
                  src={post.imageUrl}
                  alt={post.title}
                  className="w-full h-48 object-cover"
                /> */}
                <div className="p-6">
                  <div className="flex items-center text-sm text-gray-500 mb-4">
                    <Calendar className="h-4 w-4 mr-2" />
                    <span>{new Date(post.date).toLocaleDateString()}</span>
                    <span className="mx-2">•</span>
                    <Clock className="h-4 w-4 mr-2" />
                    <span>{post.readTime}</span>
                  </div>
                  <h2 className="text-xl font-semibold text-gray-900 mb-2">
                    {post.title}
                  </h2>
                  <p className="text-gray-600 mb-4">{post.excerpt}</p>
                  <motion.div
                    whileHover={{ x: 5 }}
                    className="inline-flex items-center text-red-500 hover:text-red-600"
                  >
                    <Link to={`/blog/${post.id}`} className="flex items-center">
                      Read More <ChevronRight className="ml-2 h-4 w-4" />
                    </Link>
                  </motion.div>
                </div>
              </motion.article>
            ))}
          </AnimatePresence>
        </div>
      </main>
    </div>
  );
};

export default BlogPage;
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import illustration from '../assets/images/illustration.png';
import logo from '../assets/images/log.png';

const questions = [
  {
    id: 'occasionDetails',
    title: 'Checkpoint 1: Occasion Details',
    questions: [
      {
        id: 'occasion',
        question: "What's the occasion?",
        type: 'single-select',
        required: true,
        options: [
          'Christmas',
          "Valentine's Day",
          'Anniversary',
          'Wedding',
          "Mother's Day",
          "Father's Day",
          'Baby Shower',
          'Birth',
          'Graduation',
          'Housewarming',
          'Other',
        ],
      }
    ],
  },
  {
    id: 'recipientInfo',
    title: 'Checkpoint 2: Recipient Information',
    questions: [
      {
        id: 'recipient',
        question: 'Who is this gift for?',
        type: 'single-select',
        required: true,
        options: [
          'Mother',
          'Father',
          'Son',
          'Daughter',
          'Girlfriend',
          'Boyfriend',
          'Friend',
          'Brother',
          'Sister',
          'Relatives',
          'Colleague',
          'Other',
        ],
      },
      {
        id: 'recipientGender',
        question: "What is the recipient's gender? (Optional)",
        type: 'single-select',
        required: false,
        options: ['Male', 'Female', 'Non-binary', 'Prefer not to say', 'Other'],
      },
    ],
  },
  {
    id: 'budgetPreferences',
    title: 'Checkpoint 3: Budget and Purchase Preferences',
    questions: [
      {
        id: 'budget',
        question: "What's your budget?",
        type: 'single-select',
        required: true,
        options: [
          'Under $25',
          '$25-$50',
          '$50-$100',
          '$100-$250',
          '$250-$500',
          '$500-$1000',
          '$1000-$5000',
          '$5000+',
        ],
      },
    ],
  },
  {
    id: 'interestsPreferences',
    title: 'Checkpoint 4: Recipients Interests and Preferences',
    questions: [
      {
        id: 'interests',
        question: 'What are their main interests? (Select all that apply)',
        type: 'multi-select',
        required: true,
        options: [
          'Technology',
          'Sports',
          'Arts & Crafts',
          'Reading',
          'Music',
          'Gaming',
          'Cooking',
          'Fashion',
          'Outdoors',
          'Other',
        ],
      },
      {
        id: 'giftStyle',
        question: 'Gift Style Preference: (Optional)',
        type: 'multi-select',
        required: false,
        options: [
          'Funny',
          'Serious',
          'Series-themed',
          'Personalized',
          'Practical',
          'Sentimental',
          'Unique',
          'Artisanal',
        ],
      },
    ],
  },
];

const GiftQuestionnaire = () => {
  const navigate = useNavigate();
  const [currentCheckpoint, setCurrentCheckpoint] = useState(0);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null); // Fixed this line


  const handleComplete = async () => {
    try {
      setLoading(true);
      setError(null);
    
      // const response = await fetch('/api/get-gift-recommendations', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ answers }),
      // });

      // const response = await fetch(`/api/get-gift-recommendations`, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ answers }),
      //   credentials: 'include', // Include this if you're using cookies
      // });
      const response = await fetch(`${window.location.origin}/api/get-gift-recommendations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ answers }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      navigate('/results', {
        state: {
          recommendations: data.recommendations,
          answers: answers
        }
      });
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to get recommendations. Please try again.');
      setLoading(false);
    }
  };

  const handleAnswer = (questionId, answer) => {
    setAnswers((prev) => {
      if (prev[questionId] === answer) {
        const newAnswers = { ...prev };
        delete newAnswers[questionId];
        return newAnswers;
      }
      return {
        ...prev,
        [questionId]: answer,
      };
    });
  };

  const handleMultipleAnswer = (questionId, option) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [questionId]: {
        ...(prev[questionId] || {}),
        [option]: !(prev[questionId]?.[option] || false),
      },
    }));
  };

  const isQuestionAnswered = (questionId, type, required) => {
    if (!required) return true;
    
    if (type === 'multi-select') {
      return selectedOptions[questionId] && Object.values(selectedOptions[questionId]).some(v => v);
    }
    return answers[questionId] !== undefined;
  };

  const areAllQuestionsAnswered = () => {
    const currentQuestions = questions[currentCheckpoint].questions;
    return currentQuestions.every(q => isQuestionAnswered(q.id, q.type, q.required));
  };

  const handleNext = () => {
    questions[currentCheckpoint].questions.forEach(question => {
      if (question.type === 'multi-select') {
        const selectedValues = Object.entries(selectedOptions[question.id] || {})
          .filter(([_, selected]) => selected)
          .map(([option]) => option);
        setAnswers(prev => ({
          ...prev,
          [question.id]: selectedValues,
        }));
      }
    });

    if (currentCheckpoint < questions.length - 1) {
      setCurrentCheckpoint(prev => prev + 1);
    } else {
      handleComplete();
    }
  };

  const handleBack = () => {
    if (currentCheckpoint > 0) {
      setCurrentCheckpoint(prev => prev - 1);
    } else {
      navigate('/');
    }
  };

  return (
    <div 
      className="relative min-h-screen bg-gradient-to-r from-yellow-100 via-white to-yellow-100"
      style={{ 
        background: 'linear-gradient(to right, rgb(254, 249, 195), white, rgb(254, 249, 195))'
      }}
    >

            {/* Add error display */}
            {error && (
        <div className="fixed top-4 right-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded z-50">
          {error}
        </div>
      )}
      <nav className="p-4 relative z-20">
        <div className="max-w-7xl mx-auto flex items-center">
          <Link to="/" className="flex items-center">
            <img
              src={logo}
              alt="SuggestMeGifts Logo"
              className="h-20 w-20 mr-4 transform scale-110"
            />
            <span className="text-black text-2xl md:text-3xl lg:text-4xl font-signature">
              Suggest Me Gifts
            </span>
          </Link>
        </div>
      </nav>

      <div className="absolute inset-0">
        <img
          src={illustration}
          alt="Background Illustration"
          className="w-full h-full object-cover opacity-20"
        />
      </div>

      <main className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="flex justify-center mb-8">
          <div className="flex items-center space-x-4">
            {questions.map((_, index) => (
              <div key={index} className="flex items-center">
                <div
                  className={`w-8 h-8 rounded-full flex items-center justify-center ${
                    index <= currentCheckpoint
                      ? 'bg-red-500 text-white'
                      : 'bg-gray-300 text-gray-600'
                  }`}
                >
                  {index + 1}
                </div>
                {index < questions.length - 1 && (
                  <div
                    className={`w-10 h-1 ${
                      index < currentCheckpoint ? 'bg-red-500' : 'bg-gray-300'
                    }`}
                  ></div>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="max-w-4xl mx-auto bg-white/40 backdrop-blur-md rounded-xl shadow-xl p-8 border border-white/50">
          <AnimatePresence mode="wait">
            {!loading ? (
              <motion.div
                key={currentCheckpoint}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3 }}
                className="space-y-8"
              >
                <h2 className="text-2xl font-bold text-gray-900 mb-6">
                  {questions[currentCheckpoint].title}
                </h2>

                {questions[currentCheckpoint].questions.map((questionItem) => (
                  <div key={questionItem.id} className="space-y-4">
                    <h3 className="text-xl font-semibold text-gray-800">
                      {questionItem.question}
                      {questionItem.required && <span className="text-red-500 ml-1">*</span>}
                    </h3>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      {questionItem.options.map((option) => (
                        <motion.button
                          key={option}
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.98 }}
                          className={`p-4 text-left rounded-lg border-2 ${
                            questionItem.type === 'multi-select'
                              ? selectedOptions[questionItem.id]?.[option]
                                ? 'border-red-500 bg-red-50'
                                : 'border-gray-300'
                              : answers[questionItem.id] === option
                              ? 'border-red-500 bg-red-50'
                              : 'border-gray-300'
                          } hover:border-red-500 hover:bg-red-50 transition-all duration-200`}
                          onClick={() =>
                            questionItem.type === 'multi-select'
                              ? handleMultipleAnswer(questionItem.id, option)
                              : handleAnswer(questionItem.id, option)
                          }
                        >
                          {option}
                        </motion.button>
                      ))}
                    </div>
                  </div>
                ))}

                <div className="flex justify-between items-center mt-8">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleBack}
                    className="px-8 py-3 rounded-lg text-lg font-semibold border-2 border-red-500 text-red-500 hover:bg-red-50 transition-all duration-200"
                  >
                    {currentCheckpoint === 0 ? 'Exit' : 'Back'}
                  </motion.button>

                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="bg-red-600 text-white px-8 py-3 rounded-lg text-lg font-semibold hover:bg-red-700 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100"
                    onClick={handleNext}
                    disabled={!areAllQuestionsAnswered()}
                  >
                    {currentCheckpoint === questions.length - 1 ? 'Get Recommendations' : 'Next'}
                  </motion.button>
                </div>
              </motion.div>
            ) : (
              <motion.div
                key="loading"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-center py-12"
              >
                <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-red-600 mx-auto"></div>
                <p className="mt-4 text-gray-600">Finding perfect gift suggestions...</p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </main>
    </div>
  );
};

export default GiftQuestionnaire;

import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Heart, ChevronRight, Facebook, Twitter, Instagram } from 'lucide-react';
import illustration from '../assets/images/illustration.png';
import logo from '../assets/images/log.png';

const LandingPage = () => {
  const navigate = useNavigate();

  const faqs = [
    {
      question: "How does the AI gift suggestion work?",
      answer: "Our AI analyzes your responses about the recipient's interests, occasion, and budget to provide personalized gift recommendations tailored to your specific needs."
    },
    {
      question: "Is this service really free?",
      answer: "Yes! Our AI-powered gift suggestion service is completely free to use. No hidden charges or subscription required."
    },
    {
      question: "How accurate are the gift suggestions?",
      answer: "Our AI continuously learns from user feedback to provide highly relevant suggestions. We consider multiple factors including occasion, relationship, interests, and budget to ensure accuracy."
    }
  ];

  const recentBlogs = [
    {
      title: "Top 10 Tech Gifts for 2024",
      excerpt: "Discover the latest and most exciting tech gifts that will impress your loved ones...",
      imageUrl: "https://drive.google.com/uc?export=view&id=1nwW9bck6WLTbVc9gd8yR6j-3yHRBuxfz"
    },
    {
      title: "Perfect Gift Ideas for Mother's Day",
      excerpt: "Make mom feel special with these thoughtfully curated gift suggestions...",
      imageUrl: "https://imgur.com/a/0H3yk2M"
    },
    {
      title: "Sustainable Gift Guide",
      excerpt: "Eco-friendly gift ideas that show you care about both your loved ones and the planet...",
      imageUrl: "https://drive.google.com/uc?export=view&id=12m-DLRf2dt23mwXOV-Y5Nprfh3UxfDp-"
    }
  ];

  return (
    <div className="relative min-h-screen bg-centered-gradient">
      {/* Navbar */}
      <nav className="p-4 backdrop-blur-md sticky top-0 z-50  ">
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          {/* Logo and Brand Name */}
          <Link to="/" className="flex items-center">
            <img
              src={logo}
              alt="SuggestMeGifts Logo"
              className="h-20 w-20 mr-4 transform scale-110"
            />
            <span className="text-black text-2xl md:text-3xl lg:text-4xl font-signature">
              Suggest Me Gifts
            </span>
          </Link>

          {/* Navigation Links */}
          <div className="hidden md:flex items-center space-x-8">
            <Link 
              to="/blog" 
              className="text-gray-700 hover:text-red-500 transition-colors font-medium"
            >
              Blog
            </Link>
            <Link 
              to="/faq" 
              className="text-gray-700 hover:text-red-500 transition-colors font-medium"
            >
              FAQ
            </Link>
            <button
              onClick={() => navigate('/questionnaire')}
              className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 transition-colors font-medium"
            >
              Get Started
            </button>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          {/* Left Side: Text Content */}
          <div className="lg:w-1/2 space-y-6">
            <h1 className="text-5xl sm:text-6xl font-bold text-gray-900 leading-tight font-fancy drop-shadow-lg">
              Discover the{' '}
              <span className="bg-gradient-to-r from-red-400 via-red-500 to-red-600 bg-clip-text text-transparent font-extrabold">
                Perfect Gift
              </span>{' '}
              Every Time – Powered by AI
            </h1>
            <p className="text-xl text-gray-700">
              With SuggestMeGifts, effortlessly find personalized gift ideas for every occasion and loved one.
            </p>
          </div>

          {/* Right Side: Illustration */}
          <div className="lg:w-1/2 mt-8 lg:mt-0 flex justify-center">
            <img
              src={illustration}
              alt="Gift Illustration"
              className="max-w-full h-auto transform scale-125"
              loading="lazy"
            />
          </div>
        </div>

        {/* Call-to-Action Button */}
        <div className="mt-20 flex justify-center">
          <button
            className="bg-gradient-to-r from-red-500 to-pink-500 text-white px-8 py-4 rounded-lg text-lg font-semibold hover:from-pink-500 hover:to-red-500 transition shadow-lg uppercase tracking-wider"
            onClick={() => navigate('/questionnaire')}
          >
            Find the Perfect Gift
          </button>
        </div>
      </main>

      {/* Featured Blog Posts Section */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">Latest Gift Guides</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {recentBlogs.map((blog, index) => (
              <div key={index} className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition">
                {/* <img src={blog.imageUrl} alt={blog.title} className="w-full h-48 object-cover" /> */}
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">{blog.title}</h3>
                  <p className="text-gray-600">{blog.excerpt}</p>
                  <Link to="/blog" className="mt-4 inline-flex items-center text-red-500 hover:text-red-600">
                    Read More <ChevronRight className="ml-2 h-4 w-4" />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">Frequently Asked Questions</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {faqs.map((faq, index) => (
              <div key={index} className="bg-white/80 backdrop-blur-md rounded-xl p-6 shadow-md">
                <h3 className="text-xl font-semibold text-gray-900 mb-2">{faq.question}</h3>
                <p className="text-gray-600">{faq.answer}</p>
              </div>
            ))}
          </div>
          <div className="mt-8 text-center">
            <Link to="/faq" className="inline-flex items-center text-red-500 hover:text-red-600 font-medium">
              View All FAQs <ChevronRight className="ml-2 h-4 w-4" />
            </Link>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-white/80 backdrop-blur-md pt-16 pb-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-4 gap-8 mb-8">
            {/* Company Info */}
            <div className="space-y-4">
              <Link to="/" className="flex items-center">
                <img src={logo} alt="SuggestMeGifts Logo" className="h-12 w-12 mr-2" />
                <span className="text-xl font-signature">SuggestMeGifts</span>
              </Link>
              <p className="text-gray-600">Your AI-powered gift suggestion companion for every special occasion.</p>
            </div>

            {/* Quick Links */}
            <div>
              <h4 className="text-lg font-semibold text-gray-900 mb-4">Quick Links</h4>
              <ul className="space-y-2">
                <li>
                  <Link to="/blog" className="text-gray-600 hover:text-red-500 transition-colors">Blog</Link>
                </li>
                <li>
                  <Link to="/faq" className="text-gray-600 hover:text-red-500 transition-colors">FAQ</Link>
                </li>
                <li>
                  <Link to="/about" className="text-gray-600 hover:text-red-500 transition-colors">About Us</Link>
                </li>
                <li>
                  <Link to="/contact" className="text-gray-600 hover:text-red-500 transition-colors">Contact</Link>
                </li>
              </ul>
            </div>

            {/* Legal */}
            <div>
              <h4 className="text-lg font-semibold text-gray-900 mb-4">Legal</h4>
              <ul className="space-y-2">
                <li>
                  <Link to="/privacy" className="text-gray-600 hover:text-red-500 transition-colors">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms" className="text-gray-600 hover:text-red-500 transition-colors">Terms of Service</Link>
                </li>
              </ul>
            </div>

            {/* Social Links */}
            <div>
              <h4 className="text-lg font-semibold text-gray-900 mb-4">Connect With Us</h4>
              <div className="flex space-x-4">
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-red-500 transition-colors">
                  <Facebook className="h-6 w-6" />
                </a>
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-red-500 transition-colors">
                  <Twitter className="h-6 w-6" />
                </a>
                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-red-500 transition-colors">
                  <Instagram className="h-6 w-6" />
                </a>
              </div>
            </div>
          </div>

          {/* Bottom Footer */}
          <div className="pt-8 mt-8 border-t border-gray-200">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <p className="text-gray-600 text-sm">
                © {new Date().getFullYear()} SuggestMeGifts. All rights reserved.
              </p>
              <div className="flex items-center space-x-2 mt-4 md:mt-0">
                <Heart className="h-4 w-4 text-red-500" />
                <span className="text-gray-600 text-sm">Made with love by SuggestMeGifts</span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import illustration from '../assets/images/illustration.png';
import logo from '../assets/images/log.png';

const faqs = [
  {
    category: "Using Our Service",
    questions: [
      {
        question: "How does the AI gift suggestion work?",
        answer: "Our AI analyzes your responses about the recipient's interests, occasion, and budget to provide personalized gift recommendations tailored to your specific needs."
      },
      {
        question: "Is this service really free?",
        answer: "Yes! Our AI-powered gift suggestion service is completely free to use. No hidden charges or subscription required."
      }
    ]
  },
  {
    category: "Gift Recommendations",
    questions: [
      {
        question: "How accurate are the gift suggestions?",
        answer: "Our AI continuously learns from user feedback to provide highly relevant suggestions. We consider multiple factors including occasion, relationship, interests, and budget to ensure accuracy."
      },
      {
        question: "Can I get recommendations for multiple people at once?",
        answer: "Yes, you can create separate gift profiles for different recipients and get personalized recommendations for each person."
      }
    ]
  }
];

const FAQPage = () => {
  const [openItems, setOpenItems] = useState({});

  const toggleItem = (categoryIndex, questionIndex) => {
    const key = `${categoryIndex}-${questionIndex}`;
    setOpenItems(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-r from-yellow-100 via-white to-yellow-100">
      {/* Background Image */}
      <div className="absolute inset-0">
        <img
          src={illustration}
          alt="Background Illustration"
          className="w-full h-full object-cover opacity-20"
        />
      </div>

      {/* Navbar */}
      <nav className="p-4 relative z-20">
        <div className="max-w-7xl mx-auto flex items-center">
          <Link to="/" className="flex items-center">
            <img
              src={logo}
              alt="SuggestMeGifts Logo"
              className="h-20 w-20 mr-4 transform scale-110"
            />
            <span className="text-black text-2xl md:text-3xl lg:text-4xl font-signature">
              Suggest Me Gifts
            </span>
          </Link>
        </div>
      </nav>

      <main className="relative z-10 max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Frequently Asked Questions
          </h1>
          <p className="text-xl text-gray-600">
            Find answers to common questions about our gift suggestion service
          </p>
        </motion.div>

        <div className="space-y-8">
          {faqs.map((category, categoryIndex) => (
            <motion.div
              key={categoryIndex}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: categoryIndex * 0.2 }}
              className="bg-white/40 backdrop-blur-md rounded-xl shadow-xl p-8 border border-white/50"
            >
              <h2 className="text-2xl font-semibold text-gray-900 mb-6">{category.category}</h2>
              <div className="space-y-4">
                {category.questions.map((faq, questionIndex) => (
                  <motion.div
                    key={questionIndex}
                    initial={false}
                    animate={{ backgroundColor: openItems[`${categoryIndex}-${questionIndex}`] ? 'rgba(255, 255, 255, 0.6)' : 'rgba(255, 255, 255, 0.3)' }}
                    className="rounded-lg overflow-hidden"
                  >
                    <motion.button
                      className="w-full px-6 py-4 text-left flex justify-between items-center hover:bg-white/50 transition-colors"
                      onClick={() => toggleItem(categoryIndex, questionIndex)}
                      whileHover={{ scale: 1.01 }}
                    >
                      <span className="text-lg font-medium text-gray-900">{faq.question}</span>
                      <motion.div
                        animate={{ rotate: openItems[`${categoryIndex}-${questionIndex}`] ? 180 : 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <ChevronDown className="h-5 w-5 text-gray-500" />
                      </motion.div>
                    </motion.button>
                    <AnimatePresence>
                      {openItems[`${categoryIndex}-${questionIndex}`] && (
                        <motion.div
                          initial={{ height: 0, opacity: 0 }}
                          animate={{ height: 'auto', opacity: 1 }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ duration: 0.2 }}
                          className="px-6 py-4 bg-white/30"
                        >
                          <p className="text-gray-600">{faq.answer}</p>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          ))}
        </div>
      </main>
    </div>
  );
};

export default FAQPage;
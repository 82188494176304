import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import illustration from '../assets/images/illustration.png';
import logo from '../assets/images/log.png';


const ResultsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [recommendations, setRecommendations] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [answers, setAnswers] = useState(null);

  useEffect(() => {
    const initializeData = () => {
      if (!location.state) {
        setError('No questionnaire data found');
        setTimeout(() => navigate('/questionnaire'), 2000);
        return;
      }

      const { recommendations, answers } = location.state;
      
      if (!recommendations || !answers) {
        setError('Incomplete data received');
        setTimeout(() => navigate('/questionnaire'), 2000);
        return;
      }

      setRecommendations(recommendations);
      setAnswers(answers);
      setLoading(false);
    };

    initializeData();
  }, [location.state, navigate]);

  const parseRecommendations = (text) => {
    if (!text) return [];
    
    const categories = [];
    const categoryRegex = /###\s*(.*?)\n/g;
    let match;
    let lastIndex = 0;

    while ((match = categoryRegex.exec(text)) !== null) {
      const categoryName = match[1].trim();
      const startIndex = match.index + match[0].length;
      lastIndex = categoryRegex.lastIndex;
      
      // Find the next category or end of text
      let endIndex;
      const nextMatch = text.indexOf('###', lastIndex);
      endIndex = nextMatch === -1 ? text.length : nextMatch;
      
      const categoryContent = text.substring(startIndex, endIndex).trim();
      
      // Enhanced product parsing
      const productLines = categoryContent.split('\n')
        .filter(line => line.match(/^\d+\./)); // Only process numbered lines

      const products = productLines
        .map(line => {
          try {
            const productRegex = /\d+\.\s*\*\*(.*?)\*\*\s*-\s*(.*?)\.\s*Price:\s*\$(\d+(?:\.\d{2})?)\s*\[Buy on (Amazon|Etsy|Other)\]\((.*?)\)/;
            const match = line.match(productRegex);
            if (!match) return null;
            
            return {
              name: match[1].trim(),
              description: match[2].trim(),
              price: `$${match[3]}`,
              platform: match[4],
              link: match[5],
            };
          } catch (e) {
            console.error('Error parsing product line:', line);
            return null;
          }
        })
        .filter(Boolean);

      if (products.length > 0) {
        categories.push({ name: categoryName, products });
      }
    }

    return categories;
  };

  const categories = recommendations ? parseRecommendations(recommendations) : [];

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-yellow-100 via-white to-yellow-100">
        <div className="text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-red-600 mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading your recommendations...</p>
        </div>
      </div>
    );
  }
  return (
    <div className="relative min-h-screen bg-gradient-to-r from-yellow-100 via-white to-yellow-100"
      style={{ 
        background: 'linear-gradient(to right, rgb(254, 249, 195), white, rgb(254, 249, 195))'
      }}
    >
      <div className="absolute inset-0">
        <img
          src={illustration}
          alt="Background Illustration"
          className="w-full h-full object-cover opacity-20"
        />
      </div>

      <nav className="p-4 relative z-20">
        <div className="max-w-7xl mx-auto flex items-center">
          <Link to="/" className="flex items-center group hover:opacity-90 transition-opacity">
            <img
              src={logo}
              alt="SuggestMeGifts Logo"
              className="h-20 w-20 mr-4 transform scale-110 group-hover:scale-105 transition-transform"
            />
            <span className="text-black text-2xl md:text-3xl lg:text-4xl font-signature group-hover:text-red-600 transition-colors">
              Suggest Me Gifts
            </span>
          </Link>
        </div>
      </nav>
      <main className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {error ? (
          <div className="text-center bg-white/40 backdrop-blur-sm rounded-xl shadow-xl p-8">
            <p className="text-red-500 mb-4">{error}</p>
            <button
              onClick={() => navigate('/questionnaire')}
              className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 transition"
            >
              Return to Questionnaire
            </button>
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-4xl font-bold text-center mb-12">Your Perfect Gift Recommendations</h1>
            {categories.length === 0 ? (
              <div className="text-center bg-white/40 backdrop-blur-sm rounded-xl shadow-xl p-8">
                <p className="text-gray-600 mb-4">No recommendations found. Please try again.</p>
                <button
                  onClick={() => navigate('/questionnaire')}
                  className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 transition"
                >
                  Start Over
                </button>
              </div>
            ) : (
              categories.map((category, idx) => (
                <section key={idx} className="mb-12 bg-white/40 backdrop-blur-sm rounded-xl shadow-xl p-8">
                  <h2 className="text-2xl font-semibold mb-6">{category.name}</h2>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {category.products.map((product, index) => (
                      <motion.div
                        key={index}
                        whileHover={{ scale: 1.02 }}
                        className="border rounded-lg p-4 shadow hover:shadow-lg transition bg-white"
                      >
                        <h3 className="text-xl font-bold mb-2">{product.name}</h3>
                        <p className="text-gray-700 mb-2">{product.description}</p>
                        <p className="text-gray-900 font-semibold mb-4">{product.price}</p>
                        <a
                          href={product.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-block bg-red-600 text-white px-4 py-2 rounded hover:bg-red-600 transition"
                        >
                          Buy on {product.platform}
                        </a>
                      </motion.div>
                    ))}
                  </div>
                </section>
              ))
            )}
          </motion.div>
        )}
      </main>
    </div>
  );
};

export default ResultsPage;

